import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Wrapper from '../components/Wrapper'

export default function About() {

  return (
    <Wrapper>
      <div className="theme-dark bg-primary text-text-main lg:h-[80vh] ">
        <div className="text-left text-lg flex flex-col justify-center items-center lg:items-start lg:flex-row space-y-10 lg:space-x-10 mx-auto max-w-5xl lg:max-w-5xl pt-10 lg:pt-20 lg:space-y-0">
          <StaticImage 
            layout="fixed"
            width={300}
            src={`../images/nm.jpg`}
            alt="Nicole Medvecka"
          />
          <div className="space-y-4">
            <p className="relative text-md text-justify mx-auto md:text-xl max-w-prose px-8">
              Nicole Medvecka was born and raised in Ukraine. 
              Since the age of five, she hasn't let go of her paintbrush and then started studying at the Transcarpathian Art Institute. 
              There she dived deeper into the compositional scheme, proportions, and colors. 
              Then she began to study cinematography at the Kyiv University of Theater, Film and Television.
            </p>
            <p className="relative text-md text-justify mx-auto md:text-xl max-w-prose px-8">
              Since 2015 Nicole lives and works in Berlin.
              She studied at the German Film and Television Academy Berlin (DFFB) in the field of cinematography. 
              Nicole is working on commercials, music videos, art projects, and films.
              She had workshops in "CRLS Light system" with Jakob Ballinger; colorist Dirk Meier and in "Lightning with ARRI".
              Nicole has extensive experience working with film: 16mm, super 16mm, 35mm.
              One of her projects, 4 channels film installation “Memoria”, was shot in Havana, Cuba, which had its premiere at Aksioma – Institute for Contemporary Art.
            </p>
          </div>
        </div>
        <div className="italic text-xl md:text-2xl mt-14 mx-auto max-w-prose px-8">
          "The light that exists in nature has its own language that can be understood, the image that must appear on the screen also has its own. When you get used to it, you know how to translate from one to the other."
        </div>
      </div>
    </Wrapper>
  )
}